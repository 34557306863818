$(function() {

  let sidebar_layout = readCookie('sidebarlayout');
  if(sidebar_layout) {
    $('.sidebar-wrapper, .sidebar-toggle').removeClass('open').addClass(sidebar_layout);
  }

  let profile_layout = readCookie('profilelayout');
  if(profile_layout) {
    set_profile_layout(profile_layout);
  }


  $('.sidebar-toggle').click(function(e) {
    e.preventDefault();
    if($(this).hasClass('open')) {
      $(this).removeClass('open').addClass('closed');
      $('.sidebar-wrapper').removeClass('open').addClass('closed');
      bakeCookie('sidebarlayout', 'closed');
    } else {
      $(this).removeClass('closed').addClass('open');
      $('.sidebar-wrapper').removeClass('closed').addClass('open');
      bakeCookie('sidebarlayout', 'open');
    }

  });

  function set_profile_layout(layout) {
    $('.layout-toggle').each(function() {
      $(this).removeClass('active');
      if($(this).data('layout') == layout) {
        $(this).addClass('active');
      }
    });
    if(layout == 'list') {
      $('.card-wrapper').removeClass('col-lg-4 col-md-6');
      $('.card-img-wrapper').removeClass('col-12').addClass('col-4');
      $('.card-body-wrapper').removeClass('col-12').addClass('col-8');
    } else {
      $('.card-wrapper').addClass('col-lg-4 col-md-6');
      $('.card-img-wrapper').removeClass('col-4').addClass('col-12');
      $('.card-body-wrapper').removeClass('col-8').addClass('col-12');
    }
    bakeCookie('profilelayout', layout);
  }

  $('.layout-toggle').on('click', this, function(e) {
    let layout = $(this).data('layout');
    set_profile_layout(layout);
  });

  $('#muid').on('change', function(e) {
    let selected = $(this).val();
    window.location.href = window.location.href.replace( /[\?#].*|$/, '?muid='+selected );
  });

  $(document).on('change', '.line-type-select', function(e) {
    let selectedIcon = $('option:selected', this).attr('data-icon');
    let selectedPlaceholder = $('option:selected', this).attr('data-placeholder');
    $(this).parents('.line-item-row').find('.line-type-icon i').removeClass().addClass(selectedIcon);
    $(this).parents('.line-item-row').find('.line-type-input').attr('placeholder', selectedPlaceholder);
  });

  $('.add-item-row-button').on('click', this, function(e) {
    e.preventDefault();
    let rowID = $(this).data('clonetarget');
    let srcRow = $('#'+rowID).clone();
    let int = $(this).parents('.dynamic-row-wrapper').find('.line-item-row').length + 1;
    srcRow.removeAttr('id').removeClass('d-none');
    srcRow.find('.cloned-form-element').each(function() {
      $(this).attr('name',$(this).attr('name').replace(/\[[0-9]*\]/, '['+int+']'));
    });

    srcRow.hide().insertBefore($(this).parents('.add-item-button-row')).slideDown(300);
  });

  $('input[type="file"]').change(function(e){
      var fileName = e.target.files[0].name;
      $(e.target).siblings('.custom-file-label').text(fileName);
  });


  $(document).on('click', '.delete-item', function(e) {
    e.preventDefault();
    $(this).siblings('.confirm-delete-item').toggleClass('hide');
  });

  $(document).on('click', '.confirm-delete-item', function(e) {
    e.preventDefault();
    let row = $(this).parents('.line-item-row');
    let itemType = $(this).data('itemtype');
    let itemID = $(this).data('itemid');

    $.ajax({
      method: 'POST',
      url: '/delete-item',
      dataType: 'json',
      data: { action: 'delete', itemType: itemType, itemID: itemID }
    })
    .done(function( msg ) {
      make_toast(msg);
    });

    // REMOVE ROW FROM DISPLAY
    row.slideUp(300, function() {
      row.remove();
    });
  });


  $(document).on('click', '.delete-profile', function(e) {
    e.preventDefault();
    let opentl = $(this).data('opentl');
    let closedtl = $(this).data('closedtl');
    if($(this).siblings('.confirm-delete-profile').hasClass('hide')) {
      $(this).text(opentl);
    } else {
      $(this).text(closedtl);
    }
    $(this).siblings('.confirm-delete-profile').toggleClass('hide');
  });

  $(document).on('click', '.confirm-delete-profile', function(e) {
    e.preventDefault();

    let profileID = $(this).data('profileid');
    $.ajax({
      method: 'POST',
      url: '/delete-profile',
      dataType: 'json',
      data: { action: 'delete', profileID: profileID }
    })
    .done(function( msg ) {
      make_toast(msg);
      window.location.href = '/profiles';
    });
  });



  $(document).on('click', '.delete-user', function(e) {
    e.preventDefault();
    let opentl = $(this).data('opentl');
    let closedtl = $(this).data('closedtl');
    if($(this).siblings('.confirm-delete-user').hasClass('hide')) {
      $(this).text(opentl);
    } else {
      $(this).text(closedtl);
    }
    $(this).siblings('.confirm-delete-user').toggleClass('hide');
  });

  $(document).on('click', '.confirm-delete-user', function(e) {
    e.preventDefault();

    let userID = $(this).data('userid');
    $.ajax({
      method: 'POST',
      url: '/delete-user',
      dataType: 'json',
      data: { action: 'delete', userID: userID }
    })
    .done(function( msg ) {
      make_toast(msg);
      window.location.href = '/users';
    });
  });


  function make_toast(msg) {
    let wrapper = $('.toast-wrapper');
    let delay = 0;
    $.each(msg, function(index) {
      delay += parseInt(this.type.notificationTypeHideDelay, 10);
      let autohide = this.type.notificationTypeAutoHide == '0' ? 'false' : 'true';
      let toast = `<div id="msg_${index}" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-delay="${delay}" data-autohide="${autohide}">
        <div class="toast-header">
          <i class="${this.type.notificationTypeIconClass} mr-2"></i>
          <strong class="mr-auto">${this.type.notificationTypeName}</strong>
          <small class="timestamp text-muted" data-timestamp="${this.timestamp}">${this.timestamp}</small>
          <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="toast-body">
          <div class="body-message">${this.message}</div>
        </div>
      </div>`;
      wrapper.append(toast).ready(function () {
        $('.toast').each(function() {
          $(this).toast('show');
        });
      });
    });
  }



  /* SEND LINK EVENTS TO GOOGLE ANALYTICS */
  var filetypes = /\.(pdf|doc.*|xls.*|ppt.*|txt|mp3|mp4|m4a|wma|mov|avi|wmv|flv|wav|zip|rar|exe|dmg)$/i;
  var baseHref = '';
  if (jQuery('base').attr('href') != undefined) {
    baseHref = jQuery('base').attr('href');
  }

  jQuery('a').on('click', function(event) {
    var pathArray = window.location.pathname.split( '/' );
    var category = pathArray[1];

    var el = jQuery(this);
    var track = true;
    var href = (typeof(el.attr('href')) != 'undefined' ) ? el.attr('href') :'';

    var isThisDomain = true;
    if (this.host !== window.location.host) {
      var isThisDomain = false;
    }

    if (!href.match(/^javascript:/i)) {
      var elEv = []; elEv.value=0, elEv.non_i=false;
      if (href.match(/^mailto\:/i)) {
        elEv.category = category;
        elEv.action = 'Email';
        // elEv.label = href.replace(/^mailto\:/i, ''); // email address
        elEv.label = window.location.pathname; // page path
        elEv.loc = href;

      } else if (href.match(filetypes)) {
        var extension = (/[.]/.exec(href)) ? /[^.]+$/.exec(href) : undefined;
        elEv.category = category;
        elEv.action = 'Download ' + extension[0];
        elEv.label = href.replace(/ /g,'-');
        elEv.loc = baseHref + href;

      } else if (href.match(/^https?\:/i) && !isThisDomain) {
        elEv.category = category;
        elEv.action = 'Outbound Link';
        elEv.label = href.replace(/^https?\:\/\//i, '');
        elEv.loc = href;

      } else if (href.match(/^tel\:/i)) {
        elEv.category = category;
        elEv.action = 'Click to Call';
        // elEv.label = href.replace(/^tel\:/i, ''); // phone number
        elEv.label = window.location.pathname; // page path
        elEv.loc = href;

      } else {
        track = false;
      }
    } else {
      track = false;
    }

    if (track) {
      if(window.gtag) {
        // gtag.js
        gtag('event', elEv.action, {
          'event_category': elEv.category,
          'event_label': elEv.label,
          'value': elEv.value,
          'non_interaction': elEv.non_i
        });

      } else if(window.ga && ga.create) {
        // analytics.js
        ga('send', 'event', elEv.category, elEv.action, elEv.label, elEv.value);

      } else if (window._gaq && window._gaq._getTracker) {
      // TRACKING CODE FOR OLD ANALYTICS.JS
        _gaq.push([
          '_trackEvent',
          elEv.category,
          elEv.action,
          elEv.label,
          elEv.value,
          elEv.non_i
        ]);

      }

      if ( el.attr('target') == undefined || el.attr('target').toLowerCase() != '_blank') {
        setTimeout(function() { location.href = elEv.loc; }, 400);
        return false;
      }
    }
  });





});





function bakeCookie(name,value,days) {
	if (days) {
		var date = new Date();
		date.setTime(date.getTime()+(days*24*60*60*1000));
		var expires = '; expires='+date.toUTCString();
	}	else {
    var expires = '';
  }
	document.cookie = name+'='+value+expires+'; path=/';
  // console.log('Mmmm.... smells scrumptious!');
}

function readCookie(name) {
	var nameEQ = name + '=';
	var ca = document.cookie.split(';');
	for (var i=0;i < ca.length;i++) {
		var c = ca[i];
		while (c.charAt(0)==' ') {
      c = c.substring(1,c.length);
    }
		if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length,c.length);
    }
	}
  // console.log('Look ma... A Cookie!');
	return null;
}

function eatCookie(name) {
  // console.log('Yummm...That was delicious!');
	bakeCookie(name,'',-1);
}
